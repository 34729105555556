export const pt = {
    navbarMsg: {
        inicio: "Início",
        adotar: "Adotar",
        associacoes: "Associações",
        desaparecidos: "Desaparecidos",
        notifStock: "O seu stock de '",
        notifStock2: "' está a acabar! ",
        notifStock3: "Quantidade atual: ",
        semNotifs: "Não tem notificações.",
    },
    loginMsg: {
        pass: "Palavra-passe",
        esqueceuPass: "Esqueceu-se da palavra-passe?",
        semConta: "Ainda não tens conta?",
        registar: "Registar",
        lembrar: "Lembrar-me",
    },
    registarMsg: {
        registar: "Registar",
        nome: "Nome",
        pass: "Palavra-passe",
        tipoConta: "Escolha o tipo de conta",
        particular: "Particular",
        associacao: "Associação / Canil",
        temConta: "Já tem conta?"
    },
    formAnimalMsg: {
        anunciar: "Anunciar",
        nome: "Nome",
        sexo: "Sexo",
        sexos: ["Macho", "Fêmea"],
        especie: "Espécie",
        especies: ["Cão", "Gato"],
        raca: "Raça",
        racas_caes: ["Rafeiro","Alaskan","Beagle","Boxer","Breton Sp.","Buldogue","Caniche","C. de Água","Chow Chow","Cocker Sp.","Dálmata","Doberman","G. Retriever","Husky","Labrador","Pastor Alemão","Pequinois","Perdigueiro","Pinscher","Raf. Alentejano","Rottweiler","Samoiedo","S. Bernardo","S. d'Aires","S. da Estrela","Terrier"],
        racas_gatos: ["Europeu","Angorá","Azul Russo","B. Noruega","Persa","Siamês"],
        porte: "Porte",
        portes: ["Pequeno", "Médio", "Grande", "Muito Grande"],
        idade: "Idade",
        idades: ["Bebé", "Jovem", "Adulto", "Sénior"],	
        cor: "Cor",
        cores: ["Branco","Preto","Cinzento","Castanho","Laranja","Preto e Branco","Cinzento e Branco","Castanho e Branco","Laranja e Branco","Cinzento e Preto","Castanho e Preto","Laranja e Preto","Amarelo/Dourado","Outra Cor..."],
        distrito: "Distrito",
        etiqueta: "Etiqueta",
        etiquetas: ["Adoção", "Desaparecido", "Petsitting"],
        descricao: "Descrição",
        fotografias: "Fotografias",
        publicar: "Publicar"
    },
    cardMsg: {
        verMais: "Ver mais"
    },
    pageAdotar: {
        titulo : "Adotar",
        qualquer: "Qualquer",
        pesquisar: "Pesquisar",
        distrito: "Distrito",
        especie: "Espécie",
        raca: "Raça",
        caes_opcao: "CÃES",
        gatos_opcao: "GATOS",
        idade: "Idade",
        sexo: "Sexo",
        porte: "Porte",
        cor: "Cor",

    },
    perfilAssMsg: {
        dias: ["Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"],
    },
    pageAssociacoes: {
        titulo : "Associações"

    },
    perfilMsg: {
        localizacao: "Localização",
        contacto: "Contacto",
        mensagem: "Enviar Mensagem",
        horario: "Horário",
        vazio: "Sem informação",
        editar: "Editar Perfil",
        doacoes: "Doações",
    },
    pageDesaparecido: {
        titulo: "Desaparecidos"
    },
    paginaInicialMsg: {
        tituloImagem: "Faça um animal feliz",
        tituloAdote: "Adote",
        procurar: "Procurar",
        adocao: "Animais para adoção",
        caesgatos: "Procurar cães e gatos",
        caes: "Cães",
        gatos: "Gatos",
        ultimosAnuncios: "Últimos anúncios",
        associacoesPerto: "Associações perto de si",
        encontreAssociacao: "Encontre uma associação na sua área",
        ajudarTexto: "Não pode adotar mas quer ajudar?",
        textAjudas: "Com o nosso website, pode tornar-se petsitter, voluntário numa associação ou canil, ou fazer donativos e ajudar animais que necessitam de cuidados.",
        idade: "Idade",
        sexo: "Sexo",
        distrito: "Distrito",
    },
    paginaAnimalMsg: {
        anunciado: "Anunciado por",
        anuncioCriado: "Anúncio criado em",
        carateristicas: "Caraterísticas",
        tamanho: "Tamanho",
        contactos: "Contactos",
        enviarMensagem: "Enviar Mensagem",
        adotar: "Adotar",
    },
    paginaEditarPerfil: {
        titulo: "Editar Perfil",
        dadosPessoais: "Dados Pessoais",
        alterarFoto: "Alterar Foto",
        editar: "Editar",
        telefone: "Telefone",
        codigoPostal: "Código Postal",
    },
    paginaEditarPerfilAss: {
        fechado: "Fechado",
        abertura: "Abertura",
        encerramento: "Encerramento",
        horario: "Horário",
    },
    paginaMensagens: {
        titulo: "Mensagens",
        mensagem: "Mensagem",
        semMensagens: "Ainda não tem mensagens",
    },
    paginaEliminar: {
        titulo: "Eliminar Conta",
        texto: "Tem a certeza que pretende eliminar a sua conta?",
        eliminar: "Eliminar",
        cancelar: "Cancelar",
    },
    dashboardAnimais: {
        titulo: "Animais",
        modalRemover: "Remover Animal",
        modalRemoverTexto: "Tem a certeza que pretende remover este animal: ",
        modalRemoverTexto2: "Se tiver um anuncio associado a este animal, este também será removido.",
        modalRemoverConfirmar: "Remover",
        modalRemoverCancelar: "Cancelar",
        botaoAdicionar: "Adicionar",
        nome: "Nome",
        especie: "Espécie",
        sexo: "Sexo",
        idade: "Idade",
        chip: "Chip",
        anunciado: "Anunciado",
        acoes: "Ações",
        semChip: "Sem Chip",
        modalInfoTitulo: "Dados do animal: ",
        
    },
    modalAdicionarAnimal: {
        titulo: "Registar Animal",
        tituloEditar: "Editar Animal",
        dataRecolha: "Data de Recolha",
        fotografia: "Fotografia",
        localCaptura: "Local de Captura",
        ferido: "Ferido",
        sim: "Sim",
        nao: "Não",
        nome: "Nome",
        agressivo: "Agressivo",
        identificacao: "Identificação eletrónica",
        numeroChip: "Nº de Chip",
        desparasitacao: "Desparasitação",
        temperaturaRectal:  "Temperatura Rectal",
        medicacao: "Medicação",
        camposObrigatorios: "Campos Obrigatórios",
        cancelar: "Cancelar",
        confirmar: "Confirmar",
    },
    dashboardAnuncios: {
        titulo: "Anúncios",
        modalRemover: "Remover Anúncio",
        modalRemoverTexto: "Tem a certeza que pretende remover o anúncio do animal: ",
        modalRemoverConfirmar: "Remover",
        modalRemoverCancelar: "Cancelar",
        botaoAdicionar: "Anunciar",
        nome: "Nome",
        especie: "Espécie",
        sexo: "Sexo",
        porte: "Porte",
        idade: "Idade",
        etiqueta: "Etiqueta",
        estado: "Estado",
        acoes: "Ações",
        ativar: "Ativar anúncio",
        desativar: "Desativar anúncio",
        modalAdicionarTitulo: "Anunciar Animal",
        modalModificarTitulo: "Modificar Anúncio",
        modalInfoTitulo: "Informação do Anúncio",
    },
    recuperarPassword: {
        tituloRecuperar: "Recuperar Password",
        recuperar: "Recuperar",
        cancelar: "Cancelar",
        tituloNova: "Nova Password",
        confirmePassword: "Confirme a Password",
        alterar: "Alterar password",
    },
    painelDashboard: {
        mensagens: "Mensagens",
        anuncios: "Anúncios",
        animais: "Animais",
        escalas: "Escalas",
        perfil: "Perfil",
        candidaturas: "Candidaturas",
    },
    gestaoStock: {
        botaoAdicionar: "Adicionar produto",
        nome: "Nome do produto",
        descricao: "Descrição",
        quantidadeAtual: "Quantidade atual",
        quantidadeMinima: "Quantidade mínima",
        observacoes: "Observações",
        cancelar: "Cancelar",
        adicionar: "Adicionar",
        removerProduto: "Remover produto",
        removerProdutoTexto: "Tem a certeza que pretende remover o produto: ",
        remover: "Remover",
        acoes: "Ações",
    },
    dashboardCandidaturas: {
        titulo: "Candidaturas",
        nomeCandidato: "Nome do candidato",
        cartaoCidadao: "Cartão de cidadão",
        dataCandidatura: "Data de candidatura",
        nomeAnimal: "Nome do animal",
        linkAnuncio: "Link do anúncio",
        cancelar: "Cancelar",
        cancelarCandidatura: "Cancelar candidatura",
        cancelarCandidaturaTexto: "Tem a certeza que pretende cancelar a candidatura do animal: ",
        sim: "Sim",
        nao: "Não",
        estado: "Estado",
        candidaturaCancelada: "Candidatura cancelada com sucesso",
        acoes: "Ações",
        aceitar: "Aceitar",
        candidaturaAceite: "Candidatura aceite com sucesso",
        terminar: "Concluir",
        candidaturaConcluida: "Candidatura concluída com sucesso",
        indisponivel: "Indisponível",
    },
    mensagens: {
        camposVazios: "Não pode deixar campos vazios",
        envioEmailPassword: "Foi enviado um email para o seu endereço de email com as instruções para recuperar a sua password.",
        passwordNaoCoincide: "A password não coincide",
        passwordLength: "A password tem de ter pelo menos 8 caracteres",
        passwordAlterada: "A sua password foi alterada com sucesso",
        tipoConta: "Tem de escolher o tipo de conta",
        emailRegistoEnviado: "Registo efetuado com sucesso. Verifique o seu email para ativar a sua conta.",
        erroEnvioMensagem: "Ocorreu um erro ao enviar a mensagem",
        sucessoProduto: "Produto adicionado com sucesso",
        camposObrigatorios: "Tem de preencher todos os campos obrigatórios",
        anuncioEditado: "Anuncio editado com sucesso",
        anuncioCriado: "Anuncio criado com sucesso",
        anuncioRemovido: "Anuncio removido com sucesso",
        estadoAlterado: "Estado alterado com sucesso",
        animalCriado: "Animal inserido com sucesso",
        animalEditado: "Animal editado com sucesso",
        animalRemovido: "Animal removido com sucesso",
        fotografiaInvalida: "Formato de fotografia inválido",
        erro: "Ocorreu um erro",
    },
    modalCandidatura: {
        titulo: "Candidatura de Adoção",
        cc: "Cartão de Cidadão",
        telefone: "Telefone",
        camposObrigatorios: "Campos Obrigatórios",
        morada: "Morada",
        distrito: "Distrito",
        codPostal: "Código Postal",
        declaracaoTitulo: "Declaração de Responsabilidade",
        cancelar: "Cancelar",
        confirmar: "Confirmar",
        seguinte: "Seguinte",
        voltar: "Voltar",
        checkDadosPessoais: "Declaro que aceito o tratamento dos meus dados pessoais",
        checkCondicoes: "Declaro que tenho todas a condições para adotar o animal",
        checkResponsabilidade: "Declaro que aceito os termos de responsabilidade",


    },

}