<template>
    <router-view></router-view>
</template>


<script>
export default {
    data() {
        return {
            BackgroundColor: '#F8F9FA',
        }
    },
    mounted() {
       
    }
}
</script>

<style>
.modal-backdrop {
  height:100%;
  width:100%;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>

